import React from "react"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {Page} from "../constants/page/pages"
import history from "../history"
import {
    getChangePasswordErrorMessage,
    isFetchingChangePassword,
} from "../store/selectors/temporaries/changePasswordSelectors"
import {
    getResetPasswordScore,
    getResetPasswordDisplaySuccessMessage,
} from "../store/selectors/temporaries/resetPasswordSelectors"
import {BENEFICIARY_AREA} from "../shared-components/constants/areaTypes"
import {BENEFICIARY_AREA_URL} from "../shared-components/constants/links"
import {RequestInfo} from "../shared-components/constants/requests"
import {CommonRequestKeys} from "../shared-components/common/constants/requests"

// ASYNC LAYOUTS
const BreadcrumbLayout = React.lazy(() => import("./breadcrumbLayout").catch(() => window.location.reload()))

export const AsyncBreadcrumbLayout = props => (
    <BreadcrumbLayout
        CESURHMessage={props.CESURHMessage}
        actualPage={props.actualPage}
        isControlStepDisplayed={props.isControlStepDisplayed}
        isFromBackOffice={props.isFromBackOffice}
        isReadOnly={props.isReadOnly}
        isUserConnected={props.isUserConnected}
        pageName={props.actualPage}
        visualisationMode={props.visualisationMode}
        wasAuthenticationBypassed={props.wasAuthenticationBypassed}
    />
)

// ASYNC SCENES
const UnauthorizedScene = React.lazy(() =>
    import("../shared-components/scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => (
    <UnauthorizedScene
        onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)}
        unauthorizedPage={Page.UNAUTHORIZED}
    />
)

const NotFoundScene = React.lazy(() =>
    import("../shared-components/scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

const ViewOrderScene = React.lazy(() =>
    import("../scenes/ViewOrder/viewOrderContainer").catch(() => window.location.reload()),
)

export const AsyncViewOrderScene = () => <ViewOrderScene />

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene
        areaType={BENEFICIARY_AREA}
        legalNoticePage={Page.LEGAL_NOTICE}
        urlDomiserve={BENEFICIARY_AREA_URL}
        withCGS={false}
    />
)

const RgpdScene = React.lazy(() => import("../scenes/Rgpd/rgpdContainer").catch(() => window.location.reload()))

export const AsyncRgpdScene = () => <RgpdScene areaType={BENEFICIARY_AREA} />

const LoginScene = React.lazy(() => import("../scenes/Login/loginContainer").catch(() => window.location.reload()))

export const AsyncLoginScene = () => <LoginScene />

const VisualisationChoiceScene = React.lazy(() =>
    import("../scenes/VisualisationChoice/visualisationChoiceContainer").catch(() => window.location.reload()),
)

export const AsyncVisualisationChoiceScene = () => <VisualisationChoiceScene />

const RecoverLoginScene = React.lazy(() =>
    import("../scenes/RecoverLoginPassword/recoverLoginPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncRecoverLoginScene = () => <RecoverLoginScene />

const RegistrationLayout = React.lazy(() => import("./registrationLayout").catch(() => window.location.reload()))

export const AsyncRegistrationLayout = () => <RegistrationLayout />

const RegistrationSessionChoiceScene = React.lazy(() =>
    import("../scenes/RegistrationSessionChoice/registrationSessionChoiceContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationSessionChoiceScene = () => <RegistrationSessionChoiceScene />

const RegistrationControlScene = React.lazy(() =>
    import("../scenes/RegistrationControl/registrationControlContainer").catch(() => window.location.reload()),
)

export const AsyncRegistrationControlScene = () => <RegistrationControlScene />

const RegistrationProfileScene = React.lazy(() =>
    import("../scenes/RegistrationProfile/registrationProfileContainer").catch(() => window.location.reload()),
)

export const AsyncRegistrationProfileScene = () => <RegistrationProfileScene />

const RegistrationPasswordScene = React.lazy(() =>
    import("../scenes/RegistrationPassword/registrationPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncRegistrationPasswordScene = () => <RegistrationPasswordScene />

const RegistrationConfirmationScene = React.lazy(() =>
    import("../scenes/RegistrationConfirmation/registrationConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationConfirmationScene = () => <RegistrationConfirmationScene />

const ResetPasswordScene = React.lazy(() =>
    import("../shared-components/scenes/ResetPassword/resetPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncResetPasswordScene = () => (
    <ResetPasswordScene
        areaType={BENEFICIARY_AREA}
        errorMessage={getChangePasswordErrorMessage}
        getResetPasswordDisplaySuccessMessage={getResetPasswordDisplaySuccessMessage}
        getResetPasswordScore={getResetPasswordScore}
        isFetching={isFetchingChangePassword}
        loginRoute={PagesArchitecture[Page.LOGIN].route}
        passwordRequestKeyName={RequestInfo[CommonRequestKeys.CHANGE_PASSWORD].name}
        resetPasswordPage={Page.RESET_PASSWORD}
    />
)

const OrderInformationScene = React.lazy(() =>
    import("../scenes/OrderInformation/orderInformationContainer").catch(() => window.location.reload()),
)

export const AsyncOrderInformationScene = () => <OrderInformationScene />

const OrderLayout = React.lazy(() => import("./orderLayout").catch(() => window.location.reload()))

export const AsyncOrderLayout = () => <OrderLayout />

const OrderProfileScene = React.lazy(() =>
    import("../scenes/OrderProfile/orderProfileContainer").catch(() => window.location.reload()),
)

export const AsyncOrderProfileScene = () => <OrderProfileScene />

const OrderContentScene = React.lazy(() =>
    import("../scenes/OrderContent/orderContentContainer").catch(() => window.location.reload()),
)

export const AsyncOrderContentScene = () => <OrderContentScene />

const OrderDeliveryScene = React.lazy(() =>
    import("../scenes/OrderDelivery/orderDeliveryContainer").catch(() => window.location.reload()),
)

export const AsyncOrderDeliveryScene = () => <OrderDeliveryScene />

const OrderPaymentScene = React.lazy(() =>
    import("../scenes/OrderPayment/orderPaymentContainer").catch(() => window.location.reload()),
)

export const AsyncOrderPaymentScene = () => <OrderPaymentScene />

const OrderConfirmationScene = React.lazy(() =>
    import("../scenes/OrderConfirmation/orderConfirmationContainer").catch(() => window.location.reload()),
)

export const AsyncOrderConfirmationScene = () => <OrderConfirmationScene />

const OrderSessionListScene = React.lazy(() =>
    import("../scenes/OrderSessionList/orderSessionListContainer").catch(() => window.location.reload()),
)

export const AsyncOrderSessionListScene = () => <OrderSessionListScene />

const OrderHistoryScene = React.lazy(() =>
    import("../scenes/OrderHistory/orderHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncOrderHistoryScene = () => <OrderHistoryScene />

const AccountPaymentHistoryScene = React.lazy(() =>
    import("../scenes/AccountPaymentHistory/accountPaymentHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncAccountPaymentHistoryScene = () => <AccountPaymentHistoryScene />

const TaxDocumentScene = React.lazy(() =>
    import("../scenes/TaxDocument/taxDocumentContainer").catch(() => window.location.reload()),
)

export const AsyncTaxDocumentScene = () => <TaxDocumentScene />

const UserProfileLayout = React.lazy(() => import("./userProfileLayout").catch(() => window.location.reload()))

export const AsyncUserProfileLayout = props => (
    <UserProfileLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const UserProfileAuthenticationInformationsScene = React.lazy(() =>
    import("../scenes/UserProfileAuthenticationInformations/userProfileAuthenticationInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileAuthenticationInformationsScene = () => <UserProfileAuthenticationInformationsScene />

const UserProfileProductChoiceScene = React.lazy(() =>
    import("../scenes/UserProfileProductChoice/userProfileProductChoiceContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileProductChoiceScene = () => <UserProfileProductChoiceScene />

const UserProfileMainInformationsScene = React.lazy(() =>
    import("../scenes/UserProfileMainInformations/userProfileMainInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileMainInformationsScene = () => <UserProfileMainInformationsScene />

const UserProfileDeclarationInformationsScene = React.lazy(() =>
    import("../scenes/UserProfileDeclarationInformations/userProfileDeclarationInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileDeclarationInformationsScene = () => <UserProfileDeclarationInformationsScene />

const CesuConversionLayout = React.lazy(() => import("./cesuConversionLayout").catch(() => window.location.reload()))

export const AsyncCesuConversionLayout = () => <CesuConversionLayout />

const CesuConversionChoiceScene = React.lazy(() =>
    import("../scenes/CesuConversionChoice/cesuConversionChoiceContainer").catch(() => window.location.reload()),
)

export const AsyncCesuConversionChoiceScene = () => <CesuConversionChoiceScene />

const CesuConversionAmountScene = React.lazy(() =>
    import("../scenes/CesuConversionAmount/cesuConversionAmountContainer").catch(() => window.location.reload()),
)

export const AsyncCesuConversionAmountScene = () => <CesuConversionAmountScene />

const CesuConversionDeliveryScene = React.lazy(() =>
    import("../scenes/CesuConversionDelivery/cesuConversionDeliveryContainer").catch(() => window.location.reload()),
)

export const AsyncCesuConversionDeliveryScene = () => <CesuConversionDeliveryScene />

const CesuConversionConfirmationScene = React.lazy(() =>
    import("../scenes/CesuConversionConfirmation/cesuConversionConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncCesuConversionConfirmationScene = () => <CesuConversionConfirmationScene />

const AccountPaymentLayout = React.lazy(() => import("./accountPaymentLayout").catch(() => window.location.reload()))

export const AsyncAccountPaymentLayout = () => <AccountPaymentLayout />

const AccountPaymentChoiceScene = React.lazy(() =>
    import("../scenes/AccountPaymentChoice/accountPaymentChoiceContainer").catch(() => window.location.reload()),
)

export const AsyncAccountPaymentChoiceScene = () => <AccountPaymentChoiceScene />

const AccountPaymentCesuIdentificationScene = React.lazy(() =>
    import("../scenes/AccountPaymentCesuIdentification/accountPaymentCesuIdentificationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentCesuIdentificationScene = () => <AccountPaymentCesuIdentificationScene />

const AccountPaymentCesuDeclarationScene = React.lazy(() =>
    import("../scenes/AccountPaymentCesuDeclaration/accountPaymentCesuDeclarationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentCesuDeclarationScene = () => <AccountPaymentCesuDeclarationScene />

const AccountPaymentPajeIdentificationScene = React.lazy(() =>
    import("../scenes/AccountPaymentPajeIdentification/accountPaymentPajeIdentificationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentPajeIdentificationScene = () => <AccountPaymentPajeIdentificationScene />

const AccountPaymentPajeDeclarationScene = React.lazy(() =>
    import("../scenes/AccountPaymentPajeDeclaration/accountPaymentPajeDeclarationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentPajeDeclarationScene = () => <AccountPaymentPajeDeclarationScene />

const AccountPaymentAmountScene = React.lazy(() =>
    import("../scenes/AccountPaymentAmount/accountPaymentAmountContainer").catch(() => window.location.reload()),
)

export const AsyncAccountPaymentAmountScene = () => <AccountPaymentAmountScene />

const AccountPaymentAmountOverviewScene = React.lazy(() =>
    import("../scenes/AccountPaymentAmountOverview/accountPaymentAmountOverviewContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentAmountOverviewScene = () => <AccountPaymentAmountOverviewScene />

const AccountPaymentConfirmationScene = React.lazy(() =>
    import("../scenes/AccountPaymentConfirmation/accountPaymentConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAccountPaymentConfirmationScene = () => <AccountPaymentConfirmationScene />

const AccountStatementScene = React.lazy(() =>
    import("../scenes/AccountStatement/accountStatementContainer").catch(() => window.location.reload()),
)

export const AsyncAccountStatementScene = () => <AccountStatementScene />

const CheckPaymentLayout = React.lazy(() => import("./checkPaymentLayout").catch(() => window.location.reload()))

export const AsyncCheckPaymentLayout = () => <CheckPaymentLayout />

const CheckPaymentChoiceScene = React.lazy(() =>
    import("../scenes/CheckPaymentChoice/checkPaymentChoiceContainer").catch(() => window.location.reload()),
)

export const AsyncCheckPaymentChoiceScene = () => <CheckPaymentChoiceScene />

const CheckPaymentAmountScene = React.lazy(() =>
    import("../scenes/CheckPaymentAmount/checkPaymentAmountContainer").catch(() => window.location.reload()),
)

export const AsyncCheckPaymentAmountScene = () => <CheckPaymentAmountScene />

const CheckPaymentConfirmationScene = React.lazy(() =>
    import("../scenes/CheckPaymentConfirmation/checkPaymentConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncCheckPaymentConfirmationScene = () => <CheckPaymentConfirmationScene />

const CheckPaymentHistoryScene = React.lazy(() =>
    import("../scenes/CheckPaymentHistory/checkPaymentHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncCheckPaymentHistoryScene = () => <CheckPaymentHistoryScene />

const CheckbookStatementScene = React.lazy(() =>
    import("../scenes/CheckbookStatement/checkbookStatementContainer").catch(() => window.location.reload()),
)

export const AsyncCheckbookStatementScene = () => <CheckbookStatementScene />

// Millesime trade scenes
const MillesimeTradeLayout = React.lazy(() => import("./millesimeTradeLayout").catch(() => window.location.reload()))

export const AsyncMillesimeTradeLayout = () => <MillesimeTradeLayout />

const MillesimeTradeChoiceScene = React.lazy(() =>
    import("../scenes/MillesimeTradeChoice/millesimeTradeChoiceContainer").catch(() => window.location.reload()),
)

export const AsyncMillesimeTradeChoiceScene = () => <MillesimeTradeChoiceScene />

const MillesimeTradeDeliveryScene = React.lazy(() =>
    import("../scenes/MillesimeTradeDelivery/millesimeTradeDeliveryContainer").catch(() => window.location.reload()),
)

export const AsyncMillesimeTradeDeliveryScene = () => <MillesimeTradeDeliveryScene />

const MillesimeTradePaymentScene = React.lazy(() =>
    import("../scenes/MillesimeTradePayment/millesimeTradePaymentContainer").catch(() => window.location.reload()),
)

export const AsyncMillesimeTradePaymentScene = () => <MillesimeTradePaymentScene />

const MillesimeTradeConfirmationScene = React.lazy(() =>
    import("../scenes/MillesimeTradeConfirmation/millesimeTradeConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncMillesimeTradeConfirmationScene = () => <MillesimeTradeConfirmationScene />

const DeclarationHistoryScene = React.lazy(() =>
    import("../scenes/DeclarationHistory/declarationHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncDeclarationHistoryScene = () => <DeclarationHistoryScene />
