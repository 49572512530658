import {Page} from "../page/pages"
import {PagesArchitecture} from "../page/pagesArchitecture"
import {VisualisationModes} from "../visualisationModes"
import {
    createDropdown,
    createItem,
} from "../../shared-components/common/utils/menu/menu"

const isFeatureEnabled = (page, isReadOnly, features) =>
    (!PagesArchitecture[page].featureFlag ||
        (PagesArchitecture[page].featureFlag &&
            features.includes(PagesArchitecture[page].featureFlag))) &&
    (!PagesArchitecture[page].hiddenInReadOnlyMode ||
        !(PagesArchitecture[page].hiddenInReadOnlyMode && isReadOnly))

/**
 * Return the menu
 * @param actualPage page where the user is
 * @param history        history function for navigation
 * @param features     enabled application features
 * @param isFromBo     is the user connected from the back office ?
 * @param visualisationMode the visualisation mode choosed by the user
 * @param wasAuthenticationByPassed if the user has bypassed the authentication
 */
export const menuContent = (
    actualPage,
    features,
    history,
    isContractWittholdingAllowed,
    isFromBo,
    isReadOnly,
    visualisationMode,
    wasAuthenticationByPassed,
) => {
    const orderCesuChoices = []
    const cesuAccountChoices = []
    const cesuCheckbookChoices = []
    const documentsChoices = []

    // CESU SOCIAL menu
    if (visualisationMode === VisualisationModes.CESU_SOCIAL) {
        const items = [
            createItem(
                actualPage,
                history,
                features,
                Page.ACCOUNT_PAYMENT_CHOICE,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, isReadOnly, features),
            ),
            createItem(
                actualPage,
                history,
                features,
                Page.TRANSACTIONS_HISTORY,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.TRANSACTIONS_HISTORY, isReadOnly, features),
            ),
        ]

        if (isContractWittholdingAllowed) {
            items.push(
                createItem(
                    actualPage,
                    history,
                    features,
                    Page.DECLARATION_HISTORY,
                    PagesArchitecture,
                    undefined,
                    undefined,
                    isFeatureEnabled(Page.DECLARATION_HISTORY, isReadOnly, features),
                ),
            )
        }

        items.push(
            createItem(
                actualPage,
                history,
                features,
                Page.USER_PROFILE_MAIN_INFORMATIONS,
                PagesArchitecture,
                "PROFIL",
                undefined,
                isFeatureEnabled(
                    Page.USER_PROFILE_MAIN_INFORMATIONS,
                    isReadOnly,
                    features,
                ),
            ),
        )

        return items
    }

    if (
        (wasAuthenticationByPassed && !isFromBo && isReadOnly === undefined) ||
        !visualisationMode
    ) {
        return []
    }

    if (!isFromBo) {
        orderCesuChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.ORDER_SESSION_LIST,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ORDER_SESSION_LIST, isReadOnly, features),
            ),
        )

        cesuAccountChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.ACCOUNT_STATEMENT,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ACCOUNT_STATEMENT, isReadOnly, features),
            ),
        )
        cesuAccountChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.ACCOUNT_PAYMENT_CHOICE,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, isReadOnly, features),
            ),
        )
        cesuAccountChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.ACCOUNT_PAYMENT_HISTORY,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ACCOUNT_PAYMENT_HISTORY, isReadOnly, features),
            ),
        )

        cesuCheckbookChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.CHECKBOOK_STATEMENT,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.CHECKBOOK_STATEMENT, isReadOnly, features),
            ),
        )
        cesuCheckbookChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.CHECK_PAYMENT_CHOICE,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.CHECK_PAYMENT_CHOICE, isReadOnly, features),
            ),
        )
        cesuCheckbookChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.CHECK_PAYMENT_HISTORY,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.CHECK_PAYMENT_HISTORY, isReadOnly, features),
            ),
        )

        if (isContractWittholdingAllowed) {
            cesuAccountChoices.push(
                createItem(
                    actualPage,
                    history,
                    features,
                    Page.DECLARATION_HISTORY,
                    PagesArchitecture,
                    undefined,
                    undefined,
                    isFeatureEnabled(Page.DECLARATION_HISTORY, isReadOnly, features),
                ),
            )
        }
    }

    // Just to keep the item order
    orderCesuChoices.push(
        createItem(
            actualPage,
            history,
            features,
            Page.MILLESIME_TRADE_CHOICE,
            PagesArchitecture,
            undefined,
            undefined,
            isFeatureEnabled(Page.MILLESIME_TRADE_CHOICE, isReadOnly, features),
        ),
    )
    if (!isFromBo) {
        orderCesuChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.CESU_CONVERSION_CHOICE,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.CESU_CONVERSION_CHOICE, isReadOnly, features),
            ),
        )
        orderCesuChoices.push(
            createItem(
                actualPage,
                history,
                features,
                Page.ORDER_HISTORY,
                PagesArchitecture,
                undefined,
                undefined,
                isFeatureEnabled(Page.ORDER_HISTORY, isReadOnly, features),
            ),
        )
    }

    documentsChoices.push(
        createItem(
            actualPage,
            history,
            features,
            Page.TAX_DOCUMENT,
            PagesArchitecture,
            undefined,
            undefined,
            isFeatureEnabled(Page.TAX_DOCUMENT, isReadOnly, features),
        ),
    )

    const menu = [
        createDropdown("Mes commandes de CESU", true, orderCesuChoices, "RIGHT"),
        createDropdown("Mon Compte CESU", true, cesuAccountChoices, "LEFT"),
        createDropdown("Mes Titres CESU", true, cesuCheckbookChoices, "LEFT"),
        createDropdown("Mes Documents", true, documentsChoices, "LEFT"),
    ]

    return menu
}
