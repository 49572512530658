import {startsWith} from "lodash"
import PropTypes from "prop-types"
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {Route, Router, Switch, withRouter} from "react-router-dom"
import {menuContent} from "../constants/menu/menu"
import {Order} from "../constants/orderActionsEnum"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import {disconnectUser} from "../shared-components/actions/sessionStorage/user/userActions"
import {setResponsive} from "../shared-components/actions/temporaries/menu/menuAction"
import {logoutUser} from "../shared-components/common/actions/user/userActions"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import Footer from "../shared-components/components/Footer/footer"
import MenuContainer from "../shared-components/components/Menu/menu"
import MenuTop from "../shared-components/components/MenuTop/menuTop"
import {BENEFICIARY_AREA} from "../shared-components/constants/areaTypes"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {wasAuthenticationBypassed} from "../store/selectors/localStorage/bypassedAuthenticationSelectors"
import {
    getCesuRhContracts,
    getCesuSocialContracts,
    isContractWittholdingAllowed,
} from "../store/selectors/localStorage/contractsSelectors"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/selectors/localStorage/cookiesSelectors"
import {
    getCESURHMessage,
    getEnabledFeatures,
    getNotificationWithFeature,
} from "../store/selectors/localStorage/featuresSelectors"
import {getOrderType} from "../store/selectors/localStorage/orderTypeSelectors"
import {
    getUserFullname,
    getUserPermissions,
    isFromBackOffice,
    isReadOnly,
    isUserConnected,
} from "../store/selectors/sessionStorage/userSelectors"
import {getSelectedContract, getVisualisationMode} from "../store/selectors/localStorage/visualisationSelectors"
import {isMenuResponsive} from "../store/selectors/temporaries/menuSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import {AsyncBreadcrumbLayout} from "./asyncScenes"
import "./mainLayout.scss"
import {MatomoTrackPageView} from "./matomoTrackPageView"

class MainLayout extends Component {
    componentDidMount() {
        this.checkVisualisationMode(this.props.actualPage)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actualPage !== this.props.actualPage) {
            this.checkVisualisationMode(nextProps.actualPage)
            this.checkBackOffice(nextProps.actualPage)
            this.checkBypassLogin(nextProps.actualPage)
            this.checkReadOnly(nextProps.actualPage)
        }
    }

    /**
     * Visualisation mode check
     */
    checkVisualisationMode = actualPage => {
        // Check if user is logged in without bypassing login and has not selected any visualisation mode
        if (this.props.isUserConnected && !this.props.wasAuthenticationBypassed && !this.props.visualisationMode) {
            // Force the user to go to VISUALISATION_CHOICE page
            // This is mandatory because the user could access any pages by URL otherwise
            this.props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route)
        }

        if (this.props.isUserConnected && this.props.visualisationMode) {
            const visualisationModes = PagesArchitecture[actualPage].visualisationModes
            if (visualisationModes && !visualisationModes.includes(this.props.visualisationMode)) {
                this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
            }
        }
    }

    checkBackOffice = actualPage => {
        if (
            this.props.isFromBackOffice &&
            !startsWith(actualPage, "MILLESIME") &&
            !startsWith(actualPage, "VISUALISATION_CHOICE") &&
            !startsWith(actualPage, "HOME")
        ) {
            this.props.history.push(PagesArchitecture[Page.MILLESIME_TRADE_CHOICE].route)
        }
    }

    checkBypassLogin = actualPage => {
        // If authentication was bypassed, we're not from back office and not from tutor access (readOnly === undefined) and we're not on ORDER page or LEGAL NOTICE page
        if (
            this.props.wasAuthenticationBypassed &&
            !this.props.isFromBackOffice &&
            this.props.isReadOnly === undefined &&
            !startsWith(actualPage, "ORDER") &&
            !startsWith(actualPage, "LEGAL_NOTICE")
        ) {
            // If order type is VIEW ORDER we redirect to concerned page
            if (this.props.orderType === Order.VIEW_ORDER) {
                this.props.history.push(PagesArchitecture[Page.VIEW_ORDER].route)
            } else {
                this.props.history.push(PagesArchitecture[Page.ORDER_INFORMATION].route)
            }
        }
    }

    checkReadOnly = actualPage => {
        const hiddenInReadOnlyMode = PagesArchitecture[actualPage].hiddenInReadOnlyMode
        if (hiddenInReadOnlyMode && this.props.isReadOnly) {
            this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
        }
    }

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser()
    }

    getBreadcrumbLayout = () => (
        <AsyncBreadcrumbLayout
            CESURHMessage={this.props.CESURHMessage}
            actualPage={this.props.actualPage}
            isFromBackOffice={this.props.isFromBackOffice}
            isReadOnly={this.props.isReadOnly}
            isUserConnected={this.props.isUserConnected}
            visualisationMode={this.props.visualisationMode}
            wasAuthenticationBypassed={this.props.wasAuthenticationBypassed}
        />
    )

    render() {
        const {
            actualPage,
            features,
            history,
            isContractWittholdingAllowed,
            isFromBackOffice,
            isMenuResponsive,
            isReadOnly,
            isUserConnected,
            onConnectClick,
            onContactClick,
            onCookiesClick,
            onHomeClick,
            onLegalClick,
            onProfileClick,
            onRgpdClick,
            onVisualisationChoiceClick,
            setMenuResponsive,
            userPermissions,
            username,
            visualisationMode,
            wasAuthenticationBypassed,
            displayNotificationFunction,
        } = this.props

        let displayVisualisationChoice
        if (wasAuthenticationBypassed || this.props.actualPage === Page.VISUALISATION_CHOICE) {
            displayVisualisationChoice = false
        } else {
            if (!Array.isArray(this.props.cesuRhContracts) || !Array.isArray(this.props.cesuSocialContracts)) {
                displayVisualisationChoice = false
            } else {
                const activeCesuRhContracts = this.props.cesuRhContracts.filter(contract => contract.contractActivated)
                const activeCesuSocialContracts = this.props.cesuSocialContracts.filter(
                    contract => contract.contractActivated,
                )

                displayVisualisationChoice =
                    (activeCesuRhContracts.length >= 1 && activeCesuSocialContracts.length >= 1) ||
                    (activeCesuRhContracts.length === 0 && activeCesuSocialContracts.length > 1)
            }
        }

        return (
            <Router history={history}>
                <div
                    className={
                        "main-layout" +
                        (process.env.NODE_ENV === "production" && !process.env.REACT_APP_PROD_MODE
                            ? " main-layout-bordered"
                            : "")
                    }
                >
                    <MatomoTrackPageView />
                    <MenuTop
                        displayVisualisationChoice={displayVisualisationChoice}
                        isConnected={isUserConnected}
                        onConnectClick={onConnectClick}
                        onDisconnectClick={this.onDisconnectClick}
                        onProfileClick={!wasAuthenticationBypassed ? onProfileClick : undefined}
                        onVisualisationChoiceClick={onVisualisationChoiceClick}
                        username={username}
                    />
                    <div
                        className={
                            (isMenuResponsive ? "main-page-responsive" : "main-page-not-responsive") + " main-page"
                        }
                    >
                        <div className="menu-container">
                            <MenuContainer
                                areaType={BENEFICIARY_AREA}
                                displayNotificationFunction={displayNotificationFunction}
                                features={features}
                                isConnected={isUserConnected}
                                isMenuResponsive={isMenuResponsive}
                                menuContent={menuContent(
                                    actualPage,
                                    features,
                                    history,
                                    isContractWittholdingAllowed,
                                    isFromBackOffice,
                                    isReadOnly,
                                    visualisationMode,
                                    wasAuthenticationBypassed,
                                )}
                                onConnectClick={onConnectClick}
                                onDisconnectClick={this.onDisconnectClick}
                                onHomeClick={() => (wasAuthenticationBypassed ? "" : onHomeClick())}
                                onProfileClick={onProfileClick}
                                setMenuResponsive={setMenuResponsive}
                                title="ESPACE BÉNÉFICIAIRE"
                                userPermissions={userPermissions}
                            />
                        </div>
                        <CheckBrowserContainer userAgent={window.navigator.userAgent} />
                        <div className="main-content">
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {/* Breadcrumb layout */}
                                    <Route actualPage={actualPage} component={this.getBreadcrumbLayout} path="" />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                    <CookiesNotificationContainer
                        getCookiesChoices={getCookiesChoices}
                        getModalStep={getModalStep}
                        isCookiesModalOpened={isCookiesModalOpened}
                        isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                        isUserAcceptedCookies={isUserAcceptedCookies}
                    />
                    <Footer
                        hideLegalNotice={isUserConnected && !wasAuthenticationBypassed && !visualisationMode}
                        onContactClick={onContactClick}
                        onCookiesClick={onCookiesClick}
                        onLegalClick={onLegalClick}
                        onRgpdClick={onRgpdClick}
                    />
                </div>
            </Router>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    isMenuResponsive: PropTypes.bool.isRequired,
    onConnectClick: PropTypes.func.isRequired,
    onCookiesClick: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func.isRequired,
    onLegalClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    let actualPage = getActualPage(state)
    return {
        actualPage: actualPage,
        cesuRhContracts: getCesuRhContracts(state),
        cesuSocialContracts: getCesuSocialContracts(state),
        CESURHMessage: getCESURHMessage(state),
        features: getEnabledFeatures(state),
        isFromBackOffice: isFromBackOffice(state),
        isMenuResponsive: isMenuResponsive(state),
        isReadOnly: isReadOnly(state),
        isUserConnected: isUserConnected(state) && actualPage !== Page.BYPASS_LOGIN,
        isContractWittholdingAllowed: isContractWittholdingAllowed(state, getSelectedContract(state)),
        orderType: getOrderType(state),
        username: getUserFullname(state),
        userPermissions: getUserPermissions(state),
        visualisationMode: getVisualisationMode(state),
        wasAuthenticationBypassed: wasAuthenticationBypassed(state),
        displayNotificationFunction: featureName => getNotificationWithFeature(state, featureName),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // Display cookies modal
    onCookiesClick: () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"})),

    // Go to the contact page.
    onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

    // Go to the connection page.
    onConnectClick: () => props.history.push(PagesArchitecture[Page.LOGIN].route),

    // Disconnect the current user.
    disconnectUser: () => dispatch(disconnectUser()),
    logoutUser: () => dispatch(logoutUser()),

    // Go to the home page.
    onHomeClick: () => {
        props.history.push(PagesArchitecture[Page.HOME].route)
    },
    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

    // Go to the rgpd page.
    onRgpdClick: () => props.history.push(PagesArchitecture[Page.RGPD].route),

    // Go to user profile page
    onProfileClick: () => props.history.push(PagesArchitecture[Page.USER_PROFILE_MAIN_INFORMATIONS].route),

    // Go to visualisation choice page
    onVisualisationChoiceClick: () => props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route),

    setMenuResponsive: isResponsive => {
        dispatch(setResponsive(isResponsive))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
