/**
 * The pages of the application.
 */
export const Page = {
    ACCOUNT_PAYMENT_AMOUNT: "ACCOUNT_PAYMENT_AMOUNT",
    ACCOUNT_PAYMENT_AMOUNT_OVERVIEW: "ACCOUNT_PAYMENT_AMOUNT_OVERVIEW",
    ACCOUNT_PAYMENT_CHOICE: "ACCOUNT_PAYMENT_CHOICE",
    ACCOUNT_PAYMENT_CONFIRMATION: "ACCOUNT_PAYMENT_CONFIRMATION",
    ACCOUNT_PAYMENT_CESU_DECLARATION: "ACCOUNT_PAYMENT_CESU_DECLARATION",
    ACCOUNT_PAYMENT_CESU_IDENTIFICATION: "ACCOUNT_PAYMENT_CESU_IDENTIFICATION",
    ACCOUNT_PAYMENT_PAJE_DECLARATION: "ACCOUNT_PAYMENT_PAJE_DECLARATION",
    ACCOUNT_PAYMENT_PAJE_IDENTIFICATION: "ACCOUNT_PAYMENT_PAJE_IDENTIFICATION",
    ACCOUNT_PAYMENT_HISTORY: "ACCOUNT_PAYMENT_HISTORY",
    ACCOUNT_PAYMENT_ORGANIZATION: "ACCOUNT_PAYMENT_ORGANIZATION",
    ACCOUNT_STATEMENT: "ACCOUNT_STATEMENT",
    BYPASS_LOGIN: "BYPASS_LOGIN",
    CESU_CONVERSION_CHOICE: "CESU_CONVERSION_CHOICE",
    CESU_CONVERSION_AMOUNT: "CESU_CONVERSION_AMOUNT",
    CESU_CONVERSION_DELIVERY: "CESU_CONVERSION_DELIVERY",
    CESU_CONVERSION_CONFIRMATION: "CESU_CONVERSION_CONFIRMATION",
    CHECKBOOK_STATEMENT: "CHECKBOOK_STATEMENT",
    CHECK_PAYMENT_AMOUNT: "CHECK_PAYMENT_AMOUNT",
    CHECK_PAYMENT_CHOICE: "CHECK_PAYMENT_CHOICE",
    CHECK_PAYMENT_CONFIRMATION: "CHECK_PAYMENT_CONFIRMATION",
    CHECK_PAYMENT_HISTORY: "CHECK_PAYMENT_HISTORY",
    DECLARATION_HISTORY: "DECLARATION_HISTORY",
    VISUALISATION_CHOICE: "VISUALISATION_CHOICE",
    EDIT_ORDER_PROFILE: "EDIT_ORDER_PROFILE",
    HOME: "HOME",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    MILLESIME_TRADE_CHOICE: "MILLESIME_TRADE_CHOICE",
    MILLESIME_TRADE_DELIVERY: "MILLESIME_TRADE_DELIVERY",
    MILLESIME_TRADE_PAYMENT: "MILLESIME_TRADE_PAYMENT",
    MILLESIME_TRADE_CONFIRMATION: "MILLESIME_TRADE_CONFIRMATION",
    NOT_FOUND: "NOT_FOUND",
    ORDER_INFORMATION: "ORDER_INFORMATION",
    ORDER_CONFIRMATION: "ORDER_CONFIRMATION",
    ORDER_CONTENT: "ORDER_CONTENT",
    ORDER_DELIVERY: "ORDER_DELIVERY",
    ORDER_HISTORY: "ORDER_HISTORY",
    ORDER_PAYMENT: "ORDER_PAYMENT",
    ORDER_PROFILE: "ORDER_PROFILE",
    ORDER_SESSION_LIST: "ORDER_SESSION_LIST",
    RESET_PASSWORD: "RESET_PASSWORD",
    RECOVER_LOGIN_PASSWORD: "RECOVER_LOGIN_PASSWORD",
    REGISTRATION: "REGISTRATION",
    REGISTRATION_CONFIRMATION: "REGISTRATION_CONFIRMATION",
    REGISTRATION_CONTROL: "REGISTRATION_CONTROL",
    REGISTRATION_PASSWORD: "REGISTRATION_PASSWORD",
    REGISTRATION_PROFILE: "REGISTRATION_PROFILE",
    REGISTRATION_SESSION_CHOICE: "REGISTRATION_SESSION_CHOICE",
    RGPD: "RGPD",
    TAX_DOCUMENT: "TAX_DOCUMENT",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    TRANSACTIONS_HISTORY: "TRANSACTIONS_HISTORY",
    UNAUTHORIZED: "UNAUTHORIZED",
    USER_PROFILE_MAIN_INFORMATIONS: "USER_PROFILE_MAIN_INFORMATIONS",
    USER_PROFILE_PRODUCT_CHOICE: "USER_PROFILE_PRODUCT_CHOICE",
    USER_PROFILE_AUTHENTICATION_INFORMATIONS: "USER_PROFILE_AUTHENTICATION_INFORMATIONS",
    USER_PROFILE_DECLARATION_INFORMATIONS: "USER_PROFILE_DECLARATION_INFORMATIONS",
    VIEW_ORDER: "VIEW_ORDER",
}
