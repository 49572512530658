import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests"
import {ProductTypes} from "../../../shared-components/constants/productTypes"
import {ClientType} from "../../../shared-components/constants/clientType"
import {getContracts as getBeneficiaryContracts} from "../localStorage/contractsSelectors"
import {getSelectedContract, getVisualisationMode} from "../localStorage/visualisationSelectors"
import {VisualisationModes} from "../../../constants/visualisationModes"
import {CesuAccountType} from "../../../shared-components/constants/cesuAccountType"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify if user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Get trades parameters attachted to the user contracts
 */
export const getTradesParameters = state => {
    const userDetails = getUserDetails(state)
    if (userDetails.beneficiaryForTrade) {
        return userDetails.beneficiaryForTrade.tradesParameters
    } else {
        return []
    }
}

/**
 * Get user contracts
 */
export const getContracts = state => {
    const userDetails = getUserDetails(state)
    if (userDetails.beneficiaryForTrade) {
        return userDetails.beneficiaryForTrade.contracts
    } else {
        return []
    }
}

/**
 * Verify if the user can trade to titles
 */
export const canTradeToTitles = (state, contractId) => {
    let tradesParameters = getTradesParameters(state)

    if (contractId) {
        let canTradeToTitles = false
        tradesParameters
            .filter(tradeParameters => tradeParameters.contractId === contractId)
            .forEach(tradeParameters => {
                canTradeToTitles =
                    canTradeToTitles ||
                    (tradeParameters.finalProductType === ProductTypes.TITLE && tradeParameters.enableTrade)
            })

        return canTradeToTitles
    } else {
        return false
    }
}

/**
 * Return the support type of the contract
 */
export const getSupportType = (state, contractId) => {
    const contracts = getContracts(state).filter(contract => contract.id === contractId)
    if (contracts.length !== 1) {
        return undefined
    }

    return contracts[0].supportType
}

/**
 * Tell if the user came from the back office
 */
export const isFromBackOffice = state => !!getUserDetails(state).fromBackOffice

/**
 * Tell if the user is in read only mod
 */
export const isReadOnly = state => getUserDetails(state).readOnly

/**
 * Verify if user token is fetching.
 */
export const isFetchingAuthority = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get the authority error message from the state.
 */
export const getAuthorityErrorMessage = state =>
    fromRequests.getErrorMessage(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Get user client type from the store.
 */
export const getUserClientType = state => getUserDetails(state).clientType

export const getSelectedContractInformations = state => {
    const selectedContract = getSelectedContract(state)
    const contracts = getBeneficiaryContracts(state)

    if (!Array.isArray(contracts)) {
        return {}
    }

    // If selected contract is defined then check the client type
    return contracts.filter(contract => contract.contractId === selectedContract)[0]
}
/**
 * Get cesu account type of selected contract
 */
export const getCesuAccountType = state => {
    return getSelectedContractInformations(state)
        ? getSelectedContractInformations(state).cesuAccountType
        : CesuAccountType.AMOUNT
}

/**
 * Tell the beneficiary belong to a small client
 */
export const isSmallClient = state => {
    const selectedContract = getSelectedContract(state)
    const contracts = getBeneficiaryContracts(state)

    // Contracts can be an object when not initialized
    if (!Array.isArray(contracts)) {
        return false
    }

    // Selected contract can be null if the user has multiple CESU_RH contracts
    if (!selectedContract) {
        return contracts.every(contract => contract.clientType === ClientType.PETIT_CLIENT)
    }

    // If selected contract is defined then check the client type
    const filteredContracts = contracts.filter(contract => contract.contractId === selectedContract)

    return filteredContracts.length > 0 ? filteredContracts[0].clientType === ClientType.PETIT_CLIENT : false
}

/**
 * Get user contract type
 */
export const getUserContractType = state => getUserDetails(state).contractType

/**
 * Tell if the selected contract is a social contract
 */
export const isSocialContract = state => {
    const visualisationMode = getVisualisationMode(state)
    return visualisationMode === VisualisationModes.CESU_SOCIAL
}

/**
 * Get user client ID from the store.
 */
export const getUserClientId = state => getUserDetails(state).clientId

/**
 * Get user login from the store.
 */
export const getUserLogin = state => getUserDetails(state).login

/**
 * Get user siret from the store.
 */
export const getUserSiret = state => getUserDetails(state).siret

/**
 * Get user full name
 */
export const getUserFullname = state => {
    const userDetails = getUserDetails(state)
    return userDetails.firstName + " " + userDetails.lastName
}

/**
 * Get user mail
 */
export const getUserMail = state => getUserDetails(state).mail

/**
 * Get user beneficiary
 */
export const getUserBeneficiary = state => getUserDetails(state).beneficiary

/**
 * Get user beneficiary for trade
 */
export const getUserBeneficiaryForTrade = state =>
    getUserDetails(state).beneficiaryForTrade ? getUserDetails(state).beneficiaryForTrade : {}

/**
 * Get user phoneNumber
 */
export const getUserPhoneNumber = state => getUserBeneficiaryForTrade(state).phoneNumber

/**
 * Verify if user details is fetching ?
 */
export const isFetchingUserDetails = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_INFO].name, state.requests)

/**
 * Verifiy is recover login password request is fetching
 */
export const isFetchingRecoverLoginPassword = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RECOVER_LOGIN_PASSWORD].name, state.requests)

/**
 * Tell if the beneficiary has a special autorisation to choose the payment mode he likes
 */
export const isAllPaymentModesAllowed = state =>
    getUserBeneficiary(state) ? getUserBeneficiary(state).allowAllPaymentModes : false
